<template>
  <el-dialog
    title="报告上传"
    :visible.sync="visible"
    width="500px"
    @close="close"
  >
    <el-form :model="form" ref="form" :rules="rules" label-position="top">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="" label="巡检日期">
            <el-date-picker
              v-model="form.date"
              type="date"
              :placeholder="$t('inputBox.selectADate')"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="flag" label="报告类型">
            <el-select v-model="form.flag">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <template v-if="form.flag == 1">
          <el-col :span="24">
            <lable-name name="满充满放电量"></lable-name>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="charge" label="充电量">
              <el-input placeholder="请输入内容" v-model="form.charge">
                <template slot="append">kWh</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="disCharge" label="放电量">
              <el-input placeholder="请输入内容" v-model="form.disCharge">
                <template slot="append">kWh</template>
              </el-input>
            </el-form-item>
          </el-col>
        </template>

        <el-col :span="24">
          <el-form-item prop="logo" label="报告上传">
            <el-upload
              id="upload-demo"
              class="upload-demo"
              drag
              action="#"
              :show-file-list="false"
              :http-request="handleFile"
              :multiple="false"
              :file-list="files"
              :on-remove="handleRemove"
              :on-change="getFile"
              webkitdirectory="true"
              ref="upload"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">{{ fileName }}</div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('form')"
        >确认上传</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import i18n from "@/lang";
import axios from "axios";
import LableName from "../../../../components/LableName.vue";
export default {
  components: { LableName },
  data() {
    return {
      // pickerOptions: {
      //   disabledDate: (date) => {
      //     var d =
      //       this.form.flag == 0
      //         ? this.dataForm.lastMonthInspectTime
      //         : this.dataForm.lastYearInspectTime;
      //     const today = new Date(d); // 当前日期
      //     console.log(today, "today");
      //     const diff = (today - date) / (1000 * 60 * 60 * 24); // 计算与当前日期的差值
      //     // 如果与当前日期的差值大于等于2或小于0（已过去的日期），则禁用
      //     return diff >= 2 || diff < 0;
      //   },
      // },
      baseUrl: "http://192.168.1.98:8031/",
      visible: false,
      type: 1, //1新增 2编辑
      form: {
        flag: "",
        charge: 0,
        disCharge: 0,
        file: "",
        siteId: "testDevice",
        slaveId: 1,
        date: "",
      },
      fileName: "",
      photoUrl:
        "https://energy.vilionvpp.com/weilan/api/business" +
        "/system/upload/uploadPic",
      rules: {
        companyName: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        account: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        customerId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
      },
      headers: {
        token: "",
      },
      companyOptions: [],
      customerOptions: [],
      typeOptions: [
        {
          label: "月度巡检报告",
          value: 0,
        },
        {
          label: "年度巡检报告",
          value: 1,
        },
        // {
        //   label: "月度和年度巡检报告",
        //   value: 3,
        // },
      ],
      files: [],
      dataForm: {},
    };
  },
  computed: {
    pickerOptions: {
      disabledDate: (date) => {
        var d =
          this.form.flag == 0
            ? this.dataForm.lastMonthInspectTime
            : this.dataForm.lastYearInspectTime;
        const today = new Date(d); // 当前日期
        console.log(today, "today", d);
        const diff = (today - date) / (1000 * 60 * 60 * 24); // 计算与当前日期的差值
        // 如果与当前日期的差值大于等于2或小于0（已过去的日期），则禁用
        return diff >= 1 || diff < 0;
      },
    },
  },
  mounted() {
    this.headers.token = localStorage.getItem("stewardToken");
  },
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.logo = response.data;
      console.log(this.imageUrl, this.form.logo, response, this.form.avatar);
    },
    handleFile(param) {
      this.form.uploadFile = param.file;
      // console.log(param, "hhhhhhh");
    },
    handleRemove() {},
    handleSelectChange() {
      this.$forceUpdate();
    },
    getFile(file, fileList) {
      this.files = fileList;
      // this.files = file;
      this.fileName = this.form.programName;
      console.log(file, fileList, this.files);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const config = {
            timeout: 1200000,
            headers: { token: localStorage.getItem("token") },
          };
          this.progressFlag = true;
          let formData = new FormData();
          formData.append("charge", this.form.charge);
          formData.append("disCharge", this.form.disCharge);
          formData.append("siteId", this.form.siteId);
          formData.append("flag", this.form.flag);
          formData.append("slaveId", this.form.slaveId);
          formData.append("date", this.form.date);
          this.files.forEach((v) => {
            formData.append("file", v.raw);
          });
          axios
            .post(this.baseUrl + "/weilan/api/inspect/upload", formData, config)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: i18n.t("prompt.operateSuccessfully"),
                });
                this.close();
                this.$emit("refresh");
              } else {
                this.$message({
                  type: "error",
                  message: i18n.t("prompt.operationFailure") + res.data.message,
                });
              }
            });
        }
      });
    },

    open(info = {}, dateForm = {}) {
      this.form.siteId = info.siteId;
      this.form.slaveId = info.slaveId;
      this.dataForm = dateForm;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    // handleAvatarSuccess(res, file) {
    //   console.log(999999999, res);
    //   // this.imageUrl = URL.createObjectURL(file.raw);
    // },
    beforeAvatarUpload(file) {
      // console.log(555555555555, file);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      //这里写一个上传接口，然后拿到接口返回的图片地址，赋值就行了，看他返回的是全地址还是要你自己拼
      // 那个上传成功的事件是那个action里面直接填好了上传的地址，然后可以在那个事件拿到图片地址
      return isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input {
  width: 90% !important;
}
/deep/ .el-select {
  width: 100% !important;
}
/deep/ .el-select .el-input {
  width: 90% !important;
}

/deep/ .el-form-item {
  margin-bottom: 10px !important;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

/deep/ .el-input__inner {
  font-weight: 800;
}

/deep/ img {
  width: 150px;
}

/deep/ .el-select .el-input {
  width: 100% !important;
}
/deep/ .el-input {
  width: 95% !important;
}
/deep/ .el-upload-dragger {
  width: 455px !important;
}
</style>
