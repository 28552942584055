<template>
  <div class="container">
    <div class="top">
      <el-row :gutter="5">
        <el-col :span="24">
          <div class="mode-box" style="margin-bottom: 5px">
            <div class="title">
              <lable-name name="报告"></lable-name>
            </div>
            <div class="report-box">
              <div class="report">
                <div
                  class="download flex items-middle"
                  @click="downloadReport()"
                >
                  <img src="@/assets/imgs/download.png" style="width: 40px" />
                  <div class="btn">巡检报告模版下载</div>
                </div>
                <div class="download flex items-middle" @click="uploadReport()">
                  <img src="@/assets/imgs/upload.png" style="width: 40px" />
                  <div class="btn">巡检报告上传</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="mouth-box mode-box">
            <div class="title">
              <lable-name name="月度巡检"></lable-name>
            </div>
            <div class="mouth-content">
              <div class="top flex space-around">
                <div class="date-box">
                  <el-row>
                    <el-col :span="12">
                      <div class="date-content box">
                        <div class="date-title">上次月度巡检时间</div>
                        <div class="date-val">
                          {{ inspectionData.lastMonthInspectTime }}
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="距下次月度巡检天数"
                          :countEnterNum="inspectionData.nextMonthInspectDay"
                          unit="天"
                        ></count>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="上次月度巡检项"
                          :countEnterNum="inspectionData.monthInspectNum"
                          unit="个"
                        ></count>
                      </div>
                    </el-col>

                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="上次异常巡检数"
                          :countEnterNum="
                            inspectionData.monthExceptionInspectNum
                          "
                          unit="个"
                        ></count>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <div class="day-chart">
                  <ring-1
                    :chartData="
                      (inspectionData.monthExceptionInspectNum /
                        inspectionData.monthInspectNum) *
                      100
                    "
                    :is-fresh="isFresh"
                    title="巡检异常率"
                  ></ring-1>
                </div>
              </div>
              <div class="bottom">
                <div class="title">
                  <lable-name name="运维异常分布图"></lable-name>
                </div>
                <div class="chart-content flex flex-center">
                  <div class="chart-box">
                    <radar
                      :isFresh="isFresh"
                      :indicator="indicatorData"
                      :data1="lastRadarData"
                      :data2="thisRadarData"
                    ></radar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="mouth-box mode-box">
            <div class="title">
              <lable-name name="年度巡检"></lable-name>
            </div>
            <div class="mouth-content">
              <div class="top flex space-around">
                <div class="date-box">
                  <el-row>
                    <el-col :span="12">
                      <div class="date-content box">
                        <div class="date-title">上次年度巡检时间</div>
                        <div class="date-val">
                          {{ inspectionData.lastYearInspectTime }}
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="距下次年度巡检天数"
                          :countEnterNum="inspectionData.nextYearInspectDay"
                          unit="天"
                        ></count>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="上次年度巡检项"
                          :countEnterNum="inspectionData.yearInspectNum"
                          unit="个"
                        ></count>
                      </div>
                    </el-col>

                    <el-col :span="12">
                      <div class="box">
                        <count
                          title="上次异常巡检数"
                          :countEnterNum="
                            inspectionData.yearExceptionInspectNum
                          "
                          unit="个"
                        ></count>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <div class="day-chart">
                  <ring-1
                    :chartData="
                      (inspectionData.yearExceptionInspectNum /
                        inspectionData.yearInspectNum) *
                      100
                    "
                    :is-fresh="isFresh"
                    title="巡检异常率"
                  ></ring-1>
                </div>
              </div>
              <div class="bottom">
                <div class="title">
                  <lable-name name="容量曲线变化图"></lable-name>
                </div>
                <div class="chart-content flex flex-center">
                  <div class="line-box">
                    <double-bar
                      :yData3="effData"
                      :xData="chargeData"
                      yName1="充电量"
                      yName2="放电量"
                      yName3="效率"
                      unit="kWh"
                      :is-fresh="isFresh"
                    ></double-bar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bottom-box">
      <el-row :gutter="5">
        <el-col :span="12">
          <div class="condition mode-box">
            <div class="title">
              <lable-name name="巡检情况"></lable-name>
            </div>
            <div class="content flex">
              <div class="con-data">
                <div class="box">
                  <count
                    title="累计历史巡检异常数量"
                    :countEnterNum="inspectionData.totalExceptionInspectNum"
                    unit="个"
                  ></count>
                </div>
                <div class="box">
                  <count
                    title="上月新增巡检异常数"
                    :countEnterNum="inspectionData.monthExceptionInspectNum"
                    unit="个"
                  ></count>
                </div>
              </div>
              <div class="line-box">
                <line-area
                  :xData="inspectionData.exceptMonthList"
                  :isFresh="isFresh"
                ></line-area>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="mode-box">
            <div class="fault-content mode-box">
              <div class="title">
                <lable-name name="常见故障排查"></lable-name>
              </div>
            </div>
            <div class="line-box" style="padding: 20px; box-sizing: border-box">
              <table-auto-scroll
                :cols="inspectionData.faultList"
              ></table-auto-scroll>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <upload-form ref="uploadForm"></upload-form>
  </div>
</template>
<script>
import { getInspect, inspectDownload } from "../../../api/inspection";
import DoubleBar from "../../../components/doubleBar.vue";
import LableName from "../../../components/LableName.vue";
import TableAutoScroll from "./components/tableAutoScroll.vue";
import Count from "../components/count.vue";
import Ring1 from "../components/ring1.vue";
import LineArea from "./components/lineArea.vue";
import Radar from "./components/radar.vue";
import UploadForm from "./components/uploadForm.vue";
export default {
  components: {
    LableName,
    Count,
    Ring1,
    Radar,
    UploadForm,
    DoubleBar,
    LineArea,
    TableAutoScroll,
  },
  props: {
    isFresh: {
      type: Number,
      default() {
        return 3;
      },
    },
  },
  data() {
    return {
      lastMonth: "2023.09.01",
      monthData: {
        lastTotal: 65,
        day: 15,
        fault: 4,
        ring: 0.75,
      },
      total: {
        fault: 465,
        add: 5,
      },
      deviceInfo: {
        siteId: "",
        slaveId: "",
      },
      inspectionData: {},
      indicatorData: [],
      lastRadarData: [],
      thisRadarData: [],
      dateForm: {
        lastMonthInspectTime: "",
        lastYearInspectTime: "",
      },
      chargeData: [],
      effData: [],
    };
  },
  watch: {
    isFresh(newValue, oldValue) {
      console.log(newValue, "isFresh");
      this.isFresh = newValue;
    },
  },
  mounted() {
    this.deviceInfo.siteId = this.$route.query.siteId;
    this.deviceInfo.slaveId = this.$route.query.slaveId;
    this.getData();
  },
  methods: {
    uploadReport() {
      this.$nextTick(() => {
        this.$refs.uploadForm.open(this.deviceInfo, this.dateForm);
      });
    },
    downloadReport() {
      inspectDownload().then((res) => {
        console.log(res.data.data);
        window.open("", "_self").location.href = res.data.data;
      });
    },
    getData() {
      getInspect(this.deviceInfo).then((res) => {
        console.log(res.data.data);
        this.inspectionData = res.data.data;
        this.dateForm.lastMonthInspectTime =
          this.inspectionData.lastMonthInspectTime;
        this.dateForm.lastYearInspectTime =
          this.inspectionData.lastYearInspectTime;
        let arr1 = [];
        let arr2 = [];
        console.log(
          this.inspectionData.totalMonthDistribution,
          "this.inspectionData.totalMonthDistribution"
        );
        this.inspectionData.totalMonthDistribution.forEach((v) => {
          let obj = {
            name: v.location,
            max: v.count,
          };
          this.indicatorData.push(obj);
          var flag1 = true;
          let thisMonthExceptDistribution =
            res.data.data.thisMonthExceptDistribution;
          for (
            let i = 0;
            i < res.data.data.thisMonthExceptDistribution.length;
            i++
          ) {
            if (thisMonthExceptDistribution[i].location == v.location) {
              flag1 = false;
              arr1.push(thisMonthExceptDistribution[i].count);
              break;
            }
          }
          if (flag1) {
            arr1.push(0);
          }
          console.log(arr1);
          var flag2 = true;
          let lastMonthExceptDistribution =
            res.data.data.thisMonthExceptDistribution;
          for (
            let i = 0;
            i < res.data.data.lastMonthExceptDistribution.length;
            i++
          ) {
            if (lastMonthExceptDistribution[i].location == v.location) {
              flag2 = false;
              arr2.push(lastMonthExceptDistribution[i].count);
              break;
            }
          }
          if (flag2) {
            arr2.push(0);
          }
        });
        this.lastRadarData = arr2;
        this.thisRadarData = arr1;
        this.inspectionData.chargeAndDisChargeList.forEach((v) => {
          let a = v.charge.split(",");
          let obj = {
            charge: a[0],
            discharge: a[1],
            key: v.inspectDate,
            eff: ((a[1] / a[0]) * 100).toFixed(2),
          };
          this.chargeData.push(obj);
          this.effData.push(((obj.discharge / obj.charge) * 100).toFixed(2));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow-y: scroll;
  height: 100vh;
}
.date-box {
  width: 60%;
}
.mode-box {
  background: #222e4a;
  //margin: 20px 0;
}
.box {
  margin: 20px;
}
.mouth-box,
.year-box,
.condition,
.fault-content {
  width: 100%;
}
.day-chart {
  width: 200px;
  height: 200px;
}
.chart-box {
  width: 300px;
  height: 300px;
}
.line-box {
  width: 100%;
  height: 300px;
}
.line-box1 {
  width: 70%;
  height: 300px;
}
.btn {
  line-height: 50px;
  padding-left: 10px;
}
.bottom {
  position: relative;
}
.report {
  display: flex;
  margin-left: 50px;
  width: 50%;
}
.download {
  margin: 0 50px;
  cursor: pointer;
}
.date-title {
  color: #1ca8d9;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 20px;
}
.date-val {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
}
.bottom-box {
  margin-top: 5px;
  margin-bottom: 120px;
}
</style>
