<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/scatter");
import i18n from "@/lang";
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    yData: {
      type: Object,
      default() {
        return {};
      },
    },
    isFresh: {
      type: Number,
      default() {
        return -1;
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    yData() {
      this.drawWeatherLine();
    },
    isFresh() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    let that = this;
    window.addEventListener("resize", function () {
      console.log("resize");
      // that.drawWeatherLine();
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    let that = this;
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          // padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },

        grid: {
          left: 50,
          right: 50,
          bottom: 50,
          top: 50,
        },
        legend: {
          // data: legend, //注意：图例的名字必须跟下面series数组里面的name一致
          itemHeight: 9, //改变圆圈大小
          icon: "circle",
          textStyle: {
            color: "#fff", // 图例文字颜色
          },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData.map((item) => item.inspectDate),
          nameTextStyle: {
            color: "#ACB0BB",
            fontSize: 16,
            fontWeight: 200,
            padding: [0, 0, 20, 50], //调节显示位置(与y轴label对应关系)  上、右、下、左
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          // x轴字体设置
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 12,
              color: "#ACB0BB",
            },
          },
        },
        yAxis: [
          {
            name: "个",
            type: "value",

            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.25)",
                type: "dashed",
              },
            },
            // y轴字体设置
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            name: "异常变化",
            type: "line", // 折现/面积图
            data: this.xData.map((item) => item.count),
            itemStyle: {
              color: "#2391ff",
            },
            symbol: "emptyCircle", // 几何圆
            symbolSize: 10,
            areaStyle: {
              // 区域填充样式
              color: {
                // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#2391ff", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#121f35", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            xAxisIndex: 0,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
    display: flex
    justify-content: center
}
</style>
