<template>
  <div class="tabbar-Line">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
export default {
  name: "bar",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    chartClass: {
      type: String,
      default: "55",
    },
    data1: {
      type: Array,
      default() {
        return [];
      },
    },
    data2: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    indicator: {
      type: Array,
      default() {
        return [];
      },
    },
    isFresh: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    isFresh() {
      this.drawWeatherLine();
    },
    data1() {
      this.drawWeatherLine();
    },
    data2() {
      this.drawWeatherLine();
    },
    indicator() {
      this.drawWeatherLine();
    },
    title() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", function () {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(this.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      console.log(this.indicator, this.data1, this.data2);
      // 绘制图表
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          // padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },
        radar: {
          // shape: 'circle',
          // center: ["50%", "50%"],
          // splitNumber: 3, // 雷达图圈数设置
          //图形的半径
          radius: "50%",
          legend: {
            // x: 150,
            // y: 120,
            icon: "circle",
            textStyle: {
              color: "#fft",
              fontSize: 12,
            },
            padding: 0,
            itemWidth: 10,
          },
          // legend: {
          //   data: ["Allocated Budget", "Actual Spending"],
          // },
          name: {
            // 字体颜色
            textStyle: {
              color: "#a1a6b2",
              fontSize: 10,
              fontFamily: "PingFangSC-Regular, PingFang SC;",
              // fontWeight: "500",
            },
          },
          // 设置雷达图中间射线的颜色
          axisLine: {
            lineStyle: {
              color: "#223559",
            },
          },

          indicator: this.indicator,
          //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
          splitArea: {
            show: false,
            areaStyle: {
              color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#01424C", // 设置网格的颜色
            },
          },
        },
        series: [
          {
            name: "上月",
            type: "radar",
            data: [
              {
                value: this.data1,
                // 标记的图形。
                // symbol: "rect",
                // 标记图形的大小
                // symbolSize: 0,
                symbol: "none", //去掉折线上的小圆点
                areaStyle: { color: "rgba(49, 150, 250,.3)" }, //阴影背景颜色
                lineStyle: {
                  color: "#3196fa",
                  // 阴影折线宽度
                  width: 1,
                },

                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  },
                },
              },
            ],
          },
          {
            name: "本月",
            type: "radar",
            data: [
              {
                value: this.data2,
                // 标记的图形。
                // symbol: "rect",
                // 标记图形的大小
                // symbolSize: 0,
                symbol: "none", //去掉折线上的小圆点
                areaStyle: { color: "rgba(255, 116, 90,.3)" }, //阴影背景颜色
                lineStyle: {
                  color: "#ff745a",
                  // 阴影折线宽度
                  width: 1,
                },

                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  },
                },
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabbar-Line {
  width: 100%;
  height: 100% !important;
  //position: absolute;
  //top:-20px
}
</style>
